//
// Uses bleeding edge version of leaflet. Higher than 1.0.0.beta-2
// If you run into problems check the leaflet version
// 1.0.0.beta-3 should have the zoomDelta

import L from 'leaflet';
import { setMainHeight } from './layout';

export default class {

  constructor(id) {
    // kill it if the id doesn't exist
    if (!document.getElementById(id)) return false;

    // set class variable defaults
    this.map_height = 12544;
    this.map_width = 12544;
    this.max_zoom = 20;
    this.min_zoom = 16.5;
    this.map_center = [5800, 6500];
    this.open_panel = null;

    this.init_map(id);
  };

  init_map(id) {
    // expand parent element to 100% height
    setMainHeight();

    this.map = L.map(id, {
      maxZoom: this.max_zoom,
      minZoom: this.min_zoom,
      zoomDelta: 0.5,
      zoomSnap: 0.5,
      crs: L.CRS.Simple,
      maxBoundsViscosity: 1.0,
      scrollWheelZoom: false
    });

    // setup map bounds
    this.set_map_bounds();
    this.map.fitBounds(this.area_bounds);

    // set map center
    this.map.setView(this.map.unproject(this.map_center, this.map.getMaxZoom()));

    // setup tiles and markers
    this.set_map_tiles();
    this.add_map_markers();

    // setup distillery panels
    this.panels = document.querySelectorAll('.map_card');
    this.set_panel_size();

    // zoom map to fit viewable area on resize
    this.map.on('resize', function (e) {
      this.map.fitBounds(this.area_bounds);
      this.map.setView(this.map.unproject(this.map_center, this.map.getMaxZoom()));
      this.set_panel_size();
    }, this);
  }

  //
  // Set map bounds to match image and reset coordinates to use pixels
  set_map_bounds() {
    let southWest = this.map.unproject([0, this.map_height], this.map.getMaxZoom());
    let northEast = this.map.unproject([this.map_width, 0], this.map.getMaxZoom());

    this.map_bounds = new L.LatLngBounds(southWest, northEast);
    this.map.setMaxBounds(this.map_bounds);

    // set viewable area bounds
    southWest = this.map.unproject([4026, 8078], this.map.getMaxZoom());
    northEast = this.map.unproject([8569, 4905], this.map.getMaxZoom());

    this.area_bounds = new L.LatLngBounds(southWest, northEast);
  }

  // Set panel size, limit height
  set_panel_size() {
    var map_size = this.map.getSize();

    Array.prototype.forEach.call(this.panels, function (el, idx) {
      el.style.maxHeight = (map_size.y - 60) + 'px';
      el.style.top = (map_size.y - el.offsetHeight) / 2 + 'px';
    });
  }

  //
  // Set map tiles source
  set_map_tiles() {
    L.tileLayer('/assets/map_tiles/{z}/map_tile_{x}_{y}.jpg', {
      minZoom: this.min_zoom,
      maxZoom: this.max_zoom,
      bounds: this.map_bounds,
      attribution: ''
    }).addTo(this.map);
  }

  //
  // Add icons to the map
  add_map_markers() {

    var distilleries = [
      { id: 'distillery_60', title: 'Willow Creek', point: [4522, 6911], reversed: false },
      //{ id: 'distillery_54', title: 'Red Soles', point: [6000, 7540] },
      { id: 'distillery_22', title: "Pendray's", point: [6335, 7762], reversed: false },
      { id: 'distillery_553', title: 'Grain + Vine', point: [6400, 7400], reversed: true },
      { id: 'distillery_16', title: 'Bethel Rd.', point: [6888, 7425], reversed: false },
      { id: 'distillery_27', title: 'Tin City', point: [7580, 7062], reversed: false },
      { id: 'distillery_15', title: 'Azeo', point: [7484, 5356], reversed: false },
      { id: 'distillery_23', title: 'Re:Find', point: [6668, 5333], reversed: false },
      { id: 'distillery_288', title: 'Calwise', point: [7520, 7300], reversed: false },
      { id: 'distillery_455', title: 'Donati Spirits', point: [5905, 7960], reversed: true },
      // { id: 'distillery_586', title: 'Distillery Cheval', point: [7740, 5920], reversed: true }
    ];

    // create a featureGroup for all the markers to be included in
    this.featureGroup = new L.featureGroup();

    // the html template for map markers
    var marker_tmpl = '<svg class="icon"><use xlink:href="#icon-still"></use></svg>' +
      '<span>{title}</span>';
    var marker_tmpl_reversed = '<svg class="icon"><use xlink:href="#icon-still"></use></svg><span style="margin-left: -105%">{title}</span>';

    // loop through the distilleries and create a marker for each
    // then add to the featureGroup
    distilleries.forEach(function (el, idx, array) {
      let marker_html = null;
      if (el.reversed) {
        marker_html = L.Util.template(marker_tmpl_reversed, el);
      } else {
        marker_html = L.Util.template(marker_tmpl, el);
      }

      let icon = L.divIcon({
        iconSize: null,
        iconAnchor: [0, 0],
        className: 'trail_map_icon',
        html: marker_html
      });

      let marker = L.marker(
        this.map.unproject(el.point, this.map.getMaxZoom()),
        {
          icon: icon,
          prdt_id: el.id,
          riseOnHover: true
        }
      );

      marker.addTo(this.featureGroup);

      // Set up event, Open distillery panel
      marker.on('click', function (e) {
        var panel = document.getElementById(e.target.options.prdt_id);

        // if there is an open panel close it first
        if (this.open_panel) this.close_panel();

        if (panel) {
          panel.classList.add('open');
          this.open_panel = panel;
        }
      }.bind(this));
    }, this);

    // Add Beyond Paso marker
    const beyond_paso_tmpl = '<a class="beyond_paso" href="/distilleries#beyond_paso"><svg class="icon"><use xlink:href="#icon-star"></use></svg>' +
      '<span>{title}</span></a>';
    const beyond_paso = L.marker(
      this.map.unproject([4250, 7640], this.map.getMaxZoom()),
      {
        icon: L.divIcon({
          iconSize: null,
          iconAnchor: [0, 0],
          className: 'trail_map_icon',
          html: L.Util.template(beyond_paso_tmpl, { title: 'Beyond Paso' })
        }),
        riseOnHover: true
      }
    );

    //beyond_paso.addTo(this.featureGroup);

    // add markers to map
    this.map.addLayer(this.featureGroup);

    // normalize matches() across browsers
    var matches = function (el, selector) {
      return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
    };

    // Use event delegation on parent element of panels for close buttons
    document.getElementById('map-distilleries').addEventListener('click', function (e) {
      if (this.open_panel && e.target && matches(e.target, ['.map_card--close_btn, .map_card--close_btn *'])) {
        this.close_panel();
      }
    }.bind(this));
  }

  //
  // Close distillery panel
  close_panel() {
    if (this.open_panel) {
      this.open_panel.classList.remove('open');
      this.open_panel = null;
    }
  }

  //
  // Display the column, row and zoom over each tile
  debug_map() {
    this.map.on('zoomend', function () {
      console.log("Zoom level: " + this.map.getZoom());
    }, this);

    let canvasTiles = L.tileLayer({
      minZoom: this.min_zoom,
      maxZoom: this.max_zoom
    });

    canvasTiles.createTile = function (coords) {
      var tile = L.DomUtil.create('canvas', 'leaflet-tile');
      var size = this.getTileSize();
      tile.width = size.x;
      tile.height = size.y;

      var context = tile.getContext('2d');

      context.beginPath();
      context.rect(0, 0, tile.width, tile.height);
      context.lineWidth = 2;
      context.strokeStyle = 'white';
      context.stroke();

      context.font = "20px Arial";
      context.fillStyle = 'white';
      context.fillText(coords.x + " / " + coords.y + " / " + coords.z, 80, 140);

      return tile;
    }

    canvasTiles.addTo(this.map);
  }

}
