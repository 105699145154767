import Bricks from 'bricks.js';

let bricks_options = {
  container: '.distillery_list',
  packed: 'data-packed',
  sizes: [
    { columns: 1, gutter: 20 },
    { mq: '40.0625em', columns: 2, gutter: 20 },
    { mq: '64.0625em', columns: 2, gutter: 30 },
    { mq: '90.0625em', columns: 3, gutter: 30 }
  ]
}

export function init() {
  if (document.querySelector('.distillery_list')) {
    const bricks_instance = Bricks(bricks_options).pack();

    window.addEventListener("layoutReady", function() {
      bricks_instance.pack().resize()
    });
  }

  if (document.querySelector('.distillery_list2')) {
    bricks_options['container'] = '.distillery_list2';

    const bricks_instance2 = Bricks(bricks_options).pack();

    window.addEventListener("layoutReady", function() {
      bricks_instance2.pack().resize()
    });
  }
}

export function debug(instance) {
  instance
    .on('pack',   () => console.log('ALL grid items packed.'))
    .on('update', () => console.log('NEW grid items packed.'))
    .on('resize', size => console.log('The grid has be re-packed to accommodate a new BREAKPOINT.'))
}
