//
// Mailchimp form validation and submissions
//

// jquery is already loaded but define the dependency here anyway
import $ from 'jquery';

export function init(form_id, result_id) {
  var $form = $(form_id);
  var $result = $(result_id);

  $form.submit(function(e) {
    e.preventDefault();

    if (!isValidEmail($form)) {
        var error = "A valid email address must be provided.";

        $result.html(error);
        $result.addClass("error").removeClass('success');
    } else {
        $result.removeClass("error").addClass('success');
        $result.html("Subscribing...");
        submitSubscribeForm($form, $result);
    }
  });

  $('[data-popup]').on('click', (function (e) {
    e.preventDefault();
    var target = document.getElementById(this.getAttribute('data-popup'));
    if (target) {
      target.classList.add('popup--open');
    }
  }));

  $('.popup--close, .popup_overlay').on('click', function () {
    var popup = document.querySelector('.popup--open');
    popup.classList.remove('popup--open');
  });
}

// Validate the email address in the form
function isValidEmail($form) {
  // If email is empty, show error message.
  // contains just one @
  var email = $form.find("input[type='email']").val();

  if (!email || !email.length) {
    return false;
  } else if (email.indexOf("@") == -1) {
    return false;
  }

  return true;
}

// Submit the form with an ajax/jsonp request.
// Based on http://stackoverflow.com/a/15120409/215821
function submitSubscribeForm($form, $result) {
  $.ajax({
    type: "GET",
    url: $form.attr("action"),
    data: $form.serialize(),
    cache: false,
    dataType: "json",
    contentType: "application/json; charset=utf-8",
    error: function(error){
      // According to jquery docs, this is never called for cross-domain JSONP requests
      alert("Could not connect to the registration server. Please try again later.");
    },
    success: function(data){
        if (data.result != "success") {
            var message = data.msg || "Sorry. Unable to subscribe. Please try again later.";

            $result.addClass("error").removeClass('success');

            if (data.msg && data.msg.indexOf("already subscribed") >= 0) {
                message = "You're already subscribed. Thank you.";
                $result.removeClass('error', 'success').addClass('notice');
            }

            $result.html(message);
        } else {
            $result.removeClass("error").addClass('success');
            $result.html("Thank you!<br>You must confirm the subscription in your inbox.");
        }
    }
  });
}
