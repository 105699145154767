//
// Layout functions
//

// add a custom event for throttled resize
// use like this:
// window.addEventListener("optimizedResize", function() {
//     console.log("Resource conscious resize callback!");
// });
;(function() {
    var throttle = function(type, name, obj) {
        obj = obj || window;
        var running = false;
        var func = function() {
            if (running) { return; }
            running = true;
             requestAnimationFrame(function() {
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };
        obj.addEventListener(type, func);
    };

    /* init - you can init any event */
    throttle("resize", "optimizedResize");
    throttle("scroll", "optimizedScroll");
})();


//
// Adjust the size of the main element based on the footer height
export function init() {

  window.addEventListener("load", function() {
    setMainPadding();

    // dispatch event incase anything needs to adjust
    window.dispatchEvent(new CustomEvent('layoutReady'));
  });

  window.addEventListener("optimizedResize", function() {
    setMainPadding();
  });

  // Scroll header image if one exists
  var header_img = document.querySelector('.page_header-image');
  var header = document.querySelector('.page_header');
  var event = false;
  if (header) {
    event = header.classList.contains('event_pagetype');
  }
  if (header_img && header && (event == false)) {
    window.addEventListener("optimizedScroll", function(ev) {
      moveHeaderImage(header_img, document.body.scrollTop);
    });
  }

}


//
// Adjust the size of the main element based on the footer height
function setMainPadding() {
  let footer_height = document.getElementById('site_footer').offsetHeight;
  document.getElementById('main').style.paddingBottom = footer_height + 'px';
}


// Set the main height to 100%
export function setMainHeight() {
  document.body.style.height = '100%';
  document.documentElement.style.height = '100%';
  document.getElementById('main').style.height = '100%';

  // just doing it on the main element
  // let body = document.body,
  //     html = document.documentElement;
  // let client_height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
  // document.getElementById('main').style.height = client_height + 'px';
}


//
// Animate Header Image
function moveHeaderImage(header_img, scroll_top) {
  var top = 50 - (scroll_top/30); // 30 controls the speed
  var transform = "translateY(-" + top + "%)";

  header_img.style.webkitTransform = transform;
  header_img.style.MozTransform = transform;
  header_img.style.msTransform = transform;
  header_img.style.OTransform = transform;
  header_img.style.transform = transform;
}
